import { doc, updateDoc } from 'firebase/firestore'
import { range } from 'lodash'
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context'
import { User } from '~/types'
import { db } from './firebase'

export const handleRedirectAfterAuthentication = (user: User, router: AppRouterInstance, redirectTo: string) => {
  if (user.birthYear || user.gender) {
    router.replace(redirectTo)
  } else {
    router.replace(`/demographics?redirectTo=${redirectTo}`)
  }
}

export const getBirthYearOptions = () => {
  const startYear = 1900
  const endYear = 2018
  const birthYearOptions = range(startYear, endYear)
    .map((birthYear) => ({
      id: birthYear - startYear,
      text: birthYear.toString(),
    }))
    .reverse()

  return birthYearOptions
}

export const getGenderOptions = () => {
  return [
    { id: 0, text: 'female' },
    { id: 1, text: 'male' },
    { id: 2, text: 'non-binary' },
    { id: 3, text: 'other' },
  ]
}

export const updateUserDemographics = async (userId: string, birthYear: string, gender: string): Promise<void> => {
  const userRef = doc(db, `users/${userId}`)
  return await updateDoc(userRef, {
    birthYear,
    gender,
  })
}
