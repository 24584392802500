'use client'

import Authentication from '~/components/authentication/authentication'
import UnauthenticatedLayout from '~/components/authentication/unauthenticatedLayout'
import { useEffect, useState } from 'react'
import Welcome from '~/components/welcome'
import { Keyboard, KeyboardResize } from '@capacitor/keyboard'

const Page = () => {
  const [isWelcomeStep, setIsWelcomeStep] = useState(
    !(typeof localStorage !== 'undefined' && localStorage.getItem('ios_verification_loading')),
  )

  useEffect(() => {
    Keyboard.setResizeMode({ mode: KeyboardResize.Native })
  }, [])

  return (
    <>
      {isWelcomeStep ? (
        <>
          <Welcome
            onClickContinue={() => {
              setIsWelcomeStep(false)
            }}
          />
        </>
      ) : (
        <UnauthenticatedLayout>
          <Authentication />
        </UnauthenticatedLayout>
      )}
    </>
  )
}

export default Page
