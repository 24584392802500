'use client'
import { useRouter, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
import { useOptionalUser } from '../userProvider'
import shadesLogo from '~/assets/shades_logo_2d.svg'
import Image from 'next/image'
import { Capacitor } from '@capacitor/core'
import { handleRedirectAfterAuthentication } from '~/lib/authentication'
const isIos = Capacitor.getPlatform() === 'ios'

function UnauthenticatedLayout({ children }: { children: React.ReactNode }) {
  const router = useRouter()
  const user = useOptionalUser()
  const searchParams = useSearchParams()

  useEffect(() => {
    if (!user) return
    const redirectToPath = searchParams.get('redirectToPath') || '/'

    handleRedirectAfterAuthentication(user, router, redirectToPath)
  })

  return (
    <div
      className={`w-full dynamic-screen-min-height bg-background-primary text-content-primary px-4 ${
        isIos ? 'py-20' : 'py-12'
      } text-center flex flex-col`}
    >
      <Image className="mx-auto mb-3" src={shadesLogo} alt="Shades" />
      {children}
    </div>
  )
}

export const getLayout = (children: React.ReactNode): React.ReactNode => {
  return <UnauthenticatedLayout>{children}</UnauthenticatedLayout>
}

export default UnauthenticatedLayout
