'use client'
import { useEffect, useState } from 'react'
import Image from 'next/image'
import ShadesLogo from 'assets/shades_logo_3d.png'
import Button from '~/components/button'
import { logFirebaseEvent } from '~/lib/firebase'
import { openUrlWithInAppBrowser } from '~/lib/capacitor'
import { STATIC_MARKETING_PAGES } from '~/lib/constants'
import { Capacitor } from '@capacitor/core'
const isIos = Capacitor.getPlatform() === 'ios'

export default function Welcome({ onClickContinue }: { onClickContinue: any }) {
  useEffect(() => {
    logFirebaseEvent('render_welcome')
  }, [])
  const [loaded, setLoaded] = useState(false)
  return (
    <>
      <div
        className={`h-screen w-screen flex flex-col justify-center items-center text-content-primary cf-splash-background heading_small gap-4`}
        style={{ visibility: loaded ? 'visible' : 'hidden' }}
      >
        <Image
          src={ShadesLogo}
          alt="shades logo"
          width={454}
          height={198}
          className="mx-auto w-[calc(100%_-_50px)]"
          onLoad={() => {
            setLoaded(true)
          }}
        />

        <div
          className={`w-full px-4 ${
            isIos ? 'py-20' : 'py-12'
          }  flex flex-col justify-start items-start gap-6 cf-safe-area-padding absolute bottom-0`}
        >
          <span className="text-content-inverse_primary paragraph_small">
            by continuing, you agree to the shades
            <br />
            <span
              className="text-content-inverse_primary underline"
              onClick={() => {
                logFirebaseEvent('auth_tap_terms')
                openUrlWithInAppBrowser(STATIC_MARKETING_PAGES.TERMS)
              }}
            >
              terms of service
            </span>
            &nbsp;and&nbsp;
            <span
              className="text-content-inverse_primary underline"
              onClick={() => {
                logFirebaseEvent('auth_tap_privacy')
                openUrlWithInAppBrowser(STATIC_MARKETING_PAGES.PRIVACY)
              }}
            >
              privacy policy
            </span>
          </span>
          <Button
            onClick={() => {
              logFirebaseEvent('welcome_continue')
              onClickContinue()
            }}
            content="continue with phone number"
          />
        </div>
      </div>
    </>
  )
}
