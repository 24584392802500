'use client'

import { ExclamationCircleIcon } from '@heroicons/react/outline'
import {
  ConfirmationResult,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  UserCredential,
  PhoneAuthProvider,
  signInWithCredential,
} from 'firebase/auth'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { useRouter, useSearchParams } from 'next/navigation'
import { FormEvent, useEffect, useRef, useState } from 'react'
import { useUserState } from '~/components/userProvider'
import { isCountryCodeValid } from '~/lib/countryCodes'
import { auth, logFirebaseEvent } from '~/lib/firebase'
import Button from '../button'
import CountryCodeInput from '../countryCodeInput'
import Input from '../input'
import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import { Capacitor } from '@capacitor/core'
import LogGoogleAnalyticsEvent from '~/components/analytics/logGoogleAnalyticsEvent'
import { parsePhoneNumber } from 'libphonenumber-js'
import { handleRedirectAfterAuthentication } from '~/lib/authentication'

export default function Authentication() {
  const router = useRouter()
  const searchParams = useSearchParams()

  const { isLoading, user } = useUserState()
  const [verificationLoading, setVerificationLoading] = useState(
    typeof localStorage !== 'undefined' ? localStorage.getItem('ios_verification_loading') : false,
  )
  const countryCodeInputRef = useRef<HTMLInputElement>(null)
  const phoneNumberInputRef = useRef<HTMLInputElement>(null)
  const verificationInputRef = useRef<HTMLInputElement>(null)

  const [verificationId, setVerificationId] = useState<string | undefined>(undefined)
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier | undefined>(undefined)
  const [otpConfirmationResult, setOtpConfirmationResult] = useState<ConfirmationResult | undefined>(undefined)
  const [userCredentials, setUserCredentials] = useState<UserCredential | undefined>(undefined)
  const [formError, setFormError] = useState<string | undefined>(undefined)
  const [countryCode, setCountryCode] = useState<string>(
    typeof localStorage !== 'undefined' && localStorage.getItem('userCountryCode')
      ? (localStorage.getItem('userCountryCode') as string)
      : '+1',
  )
  const [phoneNumber, setPhoneNumber] = useState<string>(
    typeof localStorage !== 'undefined' && localStorage.getItem('userPhoneNumber')
      ? (localStorage.getItem('userPhoneNumber') as string)
      : '',
  )
  const [otpCode, setOtpCode] = useState<string>('')
  const [submittingCredentials, setSubmittingCredentials] = useState(false)
  const [submittingOTP, setSubmittingOTP] = useState(false)
  const recaptchaWrapper = useRef<HTMLInputElement>(null)
  const [resendingOTP, setResendingOTP] = useState(false)
  const isIos = Capacitor.getPlatform() === 'ios'

  useEffect(() => {
    if (localStorage) {
      localStorage.removeItem('ios_verification_loading')
    }
    if (localStorage) {
      const verificationId = localStorage.getItem('ios_verification')
      setVerificationId(verificationId as string)
    }

    window.addEventListener('storage', () => {
      if (localStorage) {
        const verificationId = localStorage.getItem('ios_verification')
        setVerificationId(verificationId as string)
      }
      // ...
    })
  }, [])

  useEffect(() => {
    if (verificationId) {
      setVerificationLoading(false)
    }
  }, [verificationId])

  useEffect(() => {
    if (isLoading) {
      return
    } else if (user) {
      logFirebaseEvent('auth_login_success')
      const redirectTo = searchParams.get('redirectToPath') || '/'
      handleRedirectAfterAuthentication(user, router, redirectTo)
    } else if (verificationId || otpConfirmationResult) {
      setFormError(undefined)
      verificationInputRef.current?.focus()
    } else {
      phoneNumberInputRef.current?.focus()
      if (!isIos) {
        setRecaptchaVerifier(
          new RecaptchaVerifier(
            'join_recaptcha',
            {
              size: 'invisible',
              callback: () => {
                return
              },
              'expired-callback': () => {
                return
              },
              'error-callback': () => {
                return
              },
            },
            auth,
          ),
        )
      }
    }
  }, [isLoading, user, verificationId, otpConfirmationResult, router, searchParams, isIos])

  const handlePhoneNumberInput = (phoneNumber: string) => {
    setPhoneNumber(phoneNumber.replace(/-/g, ''))
  }

  const handleCredentialsSubmit = async (event?: FormEvent<HTMLFormElement>) => {
    try {
      if (event) {
        event.preventDefault()
      }
      setSubmittingCredentials(true)

      setFormError(undefined)

      const combinedPhoneNumber = `${countryCode}${phoneNumber}`
      if (isIos) {
        if (localStorage) {
          localStorage.setItem('ios_verification_loading', 'true')
          localStorage.setItem('userCountryCode', countryCode)
          localStorage.setItem('userPhoneNumber', phoneNumber)
        }

        const result = await FirebaseAuthentication.signInWithPhoneNumber({
          phoneNumber: combinedPhoneNumber,
        })

        setVerificationId(result.verificationId)
        if (localStorage) {
          localStorage.setItem('ios_verification', result.verificationId as string)
          window.dispatchEvent(new Event('storage'))
        }
      } else {
        if (!recaptchaVerifier) throw new Error('missing recaptchaVerifier when submitting the credentials form')
        const confirmationResult = await signInWithPhoneNumber(auth, combinedPhoneNumber, recaptchaVerifier)
        setOtpConfirmationResult(confirmationResult)
      }

      setSubmittingCredentials(false)
    } catch (err: any) {
      setSubmittingCredentials(false)
      setFormError(err.message)
    }
  }

  const handleOTPConfirmationSubmit = async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault()
      setSubmittingOTP(true)
      setFormError(undefined)

      if (isIos) {
        if (!verificationId) throw new Error('missing verificationId when submitting OTP form')

        const credential = PhoneAuthProvider.credential(verificationId, otpCode)
        const userCredentials = await signInWithCredential(auth, credential)
        setUserCredentials(userCredentials)
        if (localStorage) {
          localStorage.removeItem('ios_verification_loading')
          localStorage.removeItem('ios_verification')
        }
      } else {
        if (!otpConfirmationResult) throw new Error('missing otpConfirmationResult when submitting OTP form')

        const userCredentials = await otpConfirmationResult.confirm(otpCode)
        setUserCredentials(userCredentials)
      }
    } catch (err: any) {
      setSubmittingOTP(false)
      if (err.code === 'auth/invalid-verification-code') {
        setFormError('the verification code you entered is incorrect')
      } else {
        setFormError(err)
      }
    }
  }

  function isDisabled(type: string): boolean {
    if (type === 'credentials') {
      return (submittingCredentials ||
        !isCountryCodeValid(countryCode, true) ||
        !phoneNumber ||
        !isValidPhoneNumber(`${countryCode}${phoneNumber}`)) as boolean
    } else {
      return submittingOTP || !otpCode
    }
  }

  if (isLoading) {
    return null
  }
  if (user && !userCredentials) {
    return null
  }

  if (verificationLoading) {
    return <>Loading</>
  }

  return (
    <>
      <div ref={recaptchaWrapper}>
        <div id="join_recaptcha"></div>
      </div>
      {verificationId || otpConfirmationResult ? (
        <div className="flex flex-col gap-[22px] w-full flex-grow">
          <LogGoogleAnalyticsEvent eventName="auth_otp_confirmation_rendered" />
          <p className="text-content-secondary paragraph_medium">
            enter the 6-digit code we texted to
            <br />
            {parsePhoneNumber(countryCode + phoneNumber)?.formatInternational()}
          </p>
          <form onSubmit={handleOTPConfirmationSubmit} className="w-full flex flex-col gap-4 flex-grow">
            <Input
              type="number"
              ref={verificationInputRef}
              name="otpCode"
              value={otpCode}
              onChange={setOtpCode}
              hasFormError={!!formError}
              label="verification code"
              inputMode="numeric"
            />
            {formError && (
              <div
                onClick={() => setFormError(undefined)}
                className="flex flex-row justify-start items-center cursor-pointer w-full space-x-2"
              >
                <ExclamationCircleIcon className="h-4 w-4 text-status-error" />
                <span className="paragraph_small text-status-error">{formError}</span>
              </div>
            )}
            <div className="flex flex-col justify-start items-start gap-y-2 mt-auto text-left">
              <span
                onClick={() => {
                  if (!resendingOTP) {
                    logFirebaseEvent('auth_tap_resend_otp')
                    handleCredentialsSubmit()
                    setResendingOTP(true)
                    setTimeout(() => {
                      setResendingOTP(false)
                    }, 20000)
                  }
                }}
              >
                <span className={!resendingOTP ? 'text-content-primary' : 'text-content-terniary'}>
                  {!resendingOTP ? 're-send code' : 'new code sent'}
                </span>
              </span>
              <span
                onClick={() => {
                  if (recaptchaVerifier && recaptchaWrapper && recaptchaWrapper.current) {
                    recaptchaVerifier.clear()
                    recaptchaWrapper.current.innerHTML = `<div id="join_recaptcha"></div>`
                  }
                  logFirebaseEvent('auth_tap_change_phone_number')
                  setFormError(undefined)
                  setPhoneNumber('')
                  setOtpConfirmationResult(undefined)
                  setVerificationId(undefined)
                }}
              >
                change phone number
              </span>
              <Button
                onClick={() => {
                  logFirebaseEvent('auth_tap_take_to_shades')
                }}
                type="submit"
                content="verify"
                isLoading={submittingOTP}
                isDisabled={isDisabled('otpCode')}
              />
            </div>
          </form>
        </div>
      ) : (
        <div className="flex flex-col gap-[22px] w-full flex-grow">
          <LogGoogleAnalyticsEvent eventName="auth_phone_form_rendered" />
          <p className="text-content-secondary paragraph_medium mb-5">enter your phone number to continue</p>
          <form onSubmit={handleCredentialsSubmit} className="w-full flex flex-col gap-4 flex-grow">
            <div className="w-full flex gap-2">
              <CountryCodeInput ref={countryCodeInputRef} value={countryCode} onChange={setCountryCode} />
              <Input
                type="number"
                ref={phoneNumberInputRef}
                name="phoneNumber"
                value={phoneNumber}
                onChange={handlePhoneNumberInput}
                label="phone number"
                inputMode="tel"
              />
            </div>
            {formError && (
              <div
                onClick={() => setFormError(undefined)}
                className="flex flex-row justify-start items-center cursor-pointer w-full space-x-2"
              >
                <ExclamationCircleIcon className="h-4 w-4 text-status-error" />
                <span className="paragraph_small text-status-error">{formError}</span>
              </div>
            )}
            <div className="mt-auto">
              <Button
                onClick={() => {
                  logFirebaseEvent(`auth_phone_tap_continue`)
                }}
                type="submit"
                content="continue"
                isLoading={submittingCredentials}
                isDisabled={isDisabled('credentials')}
              />
            </div>
          </form>
        </div>
      )}
    </>
  )
}
