import { ChangeEvent, ForwardedRef, forwardRef, useCallback, useEffect, useState } from 'react'
import { isCountryCodeValid } from '~/lib/countryCodes'

type TCountryCodeInput = {
  value: string
  onChange: (val: string) => void
}

function CountryCodeInput({ value, onChange }: TCountryCodeInput, ref: ForwardedRef<HTMLInputElement>) {
  const [hasError, setHasError] = useState(false)

  const handleValidation = useCallback(() => {
    setHasError(!isCountryCodeValid(value, false))
  }, [value])

  useEffect(() => {
    handleValidation()
  }, [value, handleValidation])

  return (
    <input
      type="text"
      ref={ref}
      value={value}
      placeholder=""
      inputMode="tel"
      maxLength={8}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value || '+')}
      onBlur={handleValidation}
      className={`
        w-[108px] h-14 border-0 placeholder-transparent
        px-4 py-4 bg-background-secondary text-content-primary 
        appearance-none focus:outline-none focus:ring-0 focus:border-2
        ${hasError ? 'border-2 border-status-error' : 'focus:border-stroke-selected'}
      `}
    />
  )
}

export default forwardRef(CountryCodeInput)
