import { ForwardedRef, forwardRef, HTMLAttributes } from 'react'

type TInput = {
  type: 'text' | 'number' | 'email'
  name: string
  value: string
  onChange: (val: string) => void
  hasFormError?: boolean
  label: string
  inputMode?: HTMLAttributes<HTMLInputElement>['inputMode']
}

function Input(
  { type, name, value, onChange, hasFormError = false, label, inputMode = 'text' }: TInput,
  ref: ForwardedRef<HTMLInputElement>,
) {
  return (
    <div className="relative w-full h-14">
      <input
        ref={ref}
        type={type}
        name={name}
        inputMode={inputMode}
        value={value}
        placeholder=""
        autoCapitalize="off"
        onChange={(e) => onChange(e.target.value)}
        className={`
          w-full h-full border-0 peer placeholder-transparent
          pb-2 pt-6 px-4
          bg-background-secondary text-content-primary
          appearance-none focus:outline-none 
          focus:ring-0 focus:border-stroke-selected focus:border-2 focus:px-4
          ${hasFormError ? 'border-status-error border-2' : ''}
        `}
      />
      <label
        className={`
          absolute left-4
          text-content-terniary pointer-events-none
          peer-focus:top-2 peer-focus:text-xs peer-focus:leading-[14px]
          ${value ? 'top-2 text-xs leading-[14px]' : 'top-4 paragraph_medium'}
          ${hasFormError ? 'text-status-error' : ''}
          transition-all duration-300 ease-in-out
        `}
      >
        {label}
      </label>
    </div>
  )
}

export default forwardRef(Input)
