'use client'
import { useEffect } from 'react'
import { logFirebaseEvent } from '~/lib/firebase'
export default function LogGoogleAnalyticsEvent({ eventName }: { eventName: string }) {
  useEffect(() => {
    logFirebaseEvent(eventName)
  }, [eventName])

  return null
}
